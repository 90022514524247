.stats__ {
    &picker {
        margin-bottom: 10px;
        &__large {
            padding-right: 10px;
        }
    }

    &card {
        background-color: #eeeeee;
        box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
        padding: 10px;
        border-radius: 5px;
        min-height: 115px;

        &__value {
            text-align: center;
            font-weight: bold;
            &__error {
                text-align: center;
                font-weight: bold;
                color: red;
            }
        }

        &__category {
            text-align: center;
            color: #bfbfbe;
            font-weight: bold;
        }
    }

    &title {
        padding: 20px 20px 0px 20px;
        float: left;
        line-height: inherit;
        font-size: 1.5rem;
        margin-bottom: 20px;
        color: $primaryMain;
        font-weight: bold;
        &__main {
            padding: 0px 20px 20px 20px;
            float: left;
            line-height: inherit;
            font-size: 2rem;
            margin-bottom: 20px;
            color: $primaryMain;
            font-weight: bold;
        }
    }

    &button {
        margin-top: 7px;
        margin-bottom: 20px;
    }

    &fields {
        display: grid;
        grid-template-columns: 4fr 2fr 1fr;
        align-items: center;
        > .search__element {
            margin: 10px;
            min-width: 60px;
        }
    }
}
